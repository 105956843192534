<template>
     <Modals  @on:close="closeModal" :settingsModals="{
        'id':'modalDateNotificaciones',
        'classFather':'justify-end',
        'iconClose':'top-[-8px] left-[33px]',
        'classWidth':'lg:w-[475px] right-[-15px]',
        'active': props.settingsNotificaciones.isOpen
     }">
        <div class="flex flex-col px-[49px] pt-[39px] pb-[66px]">
            <div class="flex items-center justify-between mb-[41px]">
                    <p class="font-WT-Variable text-[22px] text-[#212322] font-[700] ">Tus notificaciones</p>
                    <svg class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" @click="storeMenu.openNotificacion" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <circle cx="12.5" cy="12.5" r="12.5" fill="#0A47ED"/>
                        <path d="M14.25 12.7516V9.4H15.4167V8.2C15.4167 7.88174 15.2937 7.57652 15.075 7.35147C14.8562 7.12643 14.5594 7 14.25 7H10.75C10.4406 7 10.1438 7.12643 9.92504 7.35147C9.70625 7.57652 9.58333 7.88174 9.58333 8.2V9.4H10.75V12.7516L9.17092 13.7758C9.11664 13.8314 9.0736 13.8975 9.04427 13.9703C9.01493 14.0431 8.99989 14.1212 9 14.2V15.4C9 15.5591 9.06146 15.7117 9.17086 15.8243C9.28025 15.9368 9.42862 16 9.58333 16H11.9167V17.8L12.5 19L13.0833 17.8V16H15.4167C15.5714 16 15.7197 15.9368 15.8291 15.8243C15.9385 15.7117 16 15.5591 16 15.4V14.2C16.0001 14.1212 15.9851 14.0431 15.9557 13.9703C15.9264 13.8975 15.8834 13.8314 15.8291 13.7758L14.25 12.7516Z" fill="white"/>
                    </svg>
                </div>
            

                <Tabs
                        validTabButton="true" :tabsData='storeTabs.notificaciones' :settingTabs="{
                            'formaTab':true,
                            'classFather':'pb-[0]',
                            'headerBefore':false,
                            'tabActive': 'blue'
                        }" >
                    <template #notificaciones>


                        <div class="flex flex-col mt-[21px]">
                            <div class="relative bg-[#F8F8F8] py-[14px] px-[17px] rounded-[5px] w-[90%]">
                                <p class="font-WT text-white text-[13px] flex items-center justify-center absolute bg-[#0A47ED] w-[29px] h-[29px] rounded-full left-[-14px] top-[-13px]">
                                    LP
                                </p>
                                <p class="font-WT text-[#727272] text-[8px]">FEE MOVISTAR COL - DIVISION ECOMMERCE</p>
                                <p class="font-WT text-[#212322] text-[14px] my-[12px]"><strong>Lorem Ipsum</strong> ha finalizado la tarea </p>
                                <div class="bg-[#FFFFFF] py-[5px] px-[10px] mb-[12px]">
                                    <p class="font-WT text-[#727272] font-[400] text-[12px]">RQ Actualización 2.0  Mapa de Sitio.</p>
                                </div>
                                <p class="text-[#686868] font-WT text-[8px] text-right">Hace un momento</p>
                            </div>
                        

                        </div>
                    </template>
                    <template #notificaciones1>
                        <div class="flex flex-col mt-[21px]">
                            <div class="relative bg-[#F8F8F8] py-[14px] px-[17px] rounded-[5px] w-[90%]">
                                <p class="font-WT text-white text-[13px] flex items-center justify-center absolute bg-[#0A47ED] w-[29px] h-[29px] rounded-full left-[-14px] top-[-13px]">
                                    LP
                                </p>
                                <p class="font-WT text-[#727272] text-[8px]">FEE MOVISTAR COL - DIVISION ECOMMERCE1</p>
                                <p class="font-WT text-[#212322] text-[14px] my-[12px]"><strong>Lorem Ipsum</strong> ha finalizado la tarea </p>
                                <div class="bg-[#FFFFFF] py-[5px] px-[10px] mb-[12px]">
                                    <p class="font-WT text-[#727272] font-[400] text-[12px]">RQ Actualización 2.0  Mapa de Sitio.</p>
                                </div>
                                <p class="text-[#686868] font-WT text-[8px] text-right">Hace un momento</p>
                            </div>
                        

                        </div>
                    </template>
                    <template #notificaciones2>
                        <div class="flex flex-col mt-[21px]">
                            <div class="relative bg-[#F8F8F8] py-[14px] px-[17px] rounded-[5px] w-[90%]">
                                <p class="font-WT text-white text-[13px] flex items-center justify-center absolute bg-[#0A47ED] w-[29px] h-[29px] rounded-full left-[-14px] top-[-13px]">
                                    LP
                                </p>
                                <p class="font-WT text-[#727272] text-[8px]">FEE MOVISTAR COL - DIVISION ECOMMERCE2</p>
                                <p class="font-WT text-[#212322] text-[14px] my-[12px]"><strong>Lorem Ipsum</strong> ha finalizado la tarea </p>
                                <div class="bg-[#FFFFFF] py-[5px] px-[10px] mb-[12px]">
                                    <p class="font-WT text-[#727272] font-[400] text-[12px]">RQ Actualización 2.0  Mapa de Sitio.</p>
                                </div>
                                <p class="text-[#686868] font-WT text-[8px] text-right">Hace un momento</p>
                            </div>
                        

                        </div>
                    </template>

                </Tabs>
        </div>
    </Modals>
  
</template>
<script setup>
    import Modals from "~/components/modals.vue";
    import { useTabs } from '~/store/tabs/index';
    import { useMenu } from '~/store/menu/index';
    import Tabs from "~/components/ui/tabs.vue";



    const props = defineProps({  
        settingsNotificaciones: { type: Array }
    });
    const emit = defineEmits(["modals:close"]);

    const storeTabs = useTabs();
    const storeMenu = useMenu();
    const closeModal = () => {
        emit('modals:close');
    }

</script>