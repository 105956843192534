<template>
    <div class="flex flex-col flex-grow fixed top-[0] left-[0] right-[0] z-[1] relative xs:pr-[30px] lg:pr-[5%]">
        <div :class="`bg-[#FFFFFF] p-4 w-full flex items-center h-[87px] after:content-[''] after:absolute after:h-[1px] after:mx-auto after:bg-[#2123221a] after:right-[5%] after:bottom-[0] 
            ${menu.pegable == true ? 'after:w-[88%]' : 'after:lg:w-[56%] after:2xl:w-[63%] after:3xl:w-[70%] after:4xl:w-[73%]'}`">
                <div class="w-full">
                    <button type="button" data-drawer-target="drawer-example" data-drawer-show="drawer-example" aria-controls="drawer-example">
                        <img @click="menu.openMenu" src="../assets/images/icon-hamburger.svg" class="cursor-pointer w-[24px] h-[24px] md:hidden" alt="WundermanThompson" width="24" height="24"/>
                    </button>
                </div>
                <div class="flex justify-end items-center w-full">
                    <div class="flex items-center md:flex-col">
                        <p class="font-WT text-[#212322] text-[16px] font-[400] mb-[-5px]">{{ headerSettings.FullName }}</p>
                        <p class="font-WT text-[#868686] text-[12px] font-[400]">{{ headerSettings.Profile }}</p>
                    </div>
                    <img :src="`${headerSettings.Thumbnail != '' ? headerSettings.Thumbnail : 'https://flowbite.com/docs/images/people/profile-picture-1.jpg'}`" class="rounded-[29px] w-[40px] h-[40px] ml-[11px] mr-[16px]" alt="WundermanThompson" width="40" height="40"/>
                    <img src="~/assets/images/icon-campana.svg"  class="border-l-[1px] border-solid border-[#2123221a] cursor-pointer pl-[16px] w-[45px] h-[45px]" @click="isOpenModalNotificaciones" alt="WundermanThompson" width="26" height="26"/>
                    <Notificaciones :settingsNotificaciones="{
                        'isOpen': isOpenNotificaciones
                    }"
                    @modals:close="handleNotificacionesClose"/>
                </div>

            </div>
            
        </div>
</template>
<script setup>
import { initDrawers } from 'flowbite'
import { useMenu } from '~/store/menu/index';
import Notificaciones from "~/components/notificaciones.vue";



const props = defineProps({
    headerSettings: { type: Array }
});

const menu = useMenu()
const isOpenNotificaciones = ref(false);

const isOpenModalNotificaciones = () => {
    isOpenNotificaciones.value = true
}

const handleNotificacionesClose = () => {
    isOpenNotificaciones.value = false;
}

onMounted(() => {
    initDrawers();
})
    

</script>