<template>
    <Menu />
    <Header :headerSettings="{
        'FullName':Firstname + ' ' + Lastname, 
        'Profile': '',
        'Thumbnail':Thumbnail
    }"/>
   
    <div :class="`flex flex-col md:flex-row ${layoutFather()}`" :style="storeMenu.IsNotificacion ? '; transition: width 0.3s ease;':''">
        <div :class="`bg-[#FFFFFF] p-4 w-full xs:px-[30px] ${layoutPegable()} ` ">
            <slot></slot>
        </div>
        
    </div>

   
    
</template>

<script setup>

    import Header from "~/components/header.vue";
    import Menu from "~/components/menu.vue";

    import useLoadApi from '~/composables/useLoadApi';
    import { GetUserData } from '~/api/user';
    import { useAuth } from '~/store/auth/index';
    import { useMenu } from '~/store/menu/index';
    import { useUser } from '~/store/user/index';


    const storeMenu = useMenu();
    const storeAuth = useAuth();
    const route = useRoute();
    const {    
        loadMarcas,
        loadAreas,
        loadUsuarios,
        loadEstados,
        loadPrioridades,
        loadClientes,

    } = useLoadApi()

   

    const storeUser = useUser()

    if(storeUser.marcas == null) {
        storeUser.marcas = await loadMarcas();
    }

    if(storeUser.areas == null) {
        storeUser.areas = await loadAreas();
    }

    if(storeUser.usuarios == null) {
        storeUser.usuarios = await loadUsuarios();
    }

    if(storeUser.prioridades == null) {
        storeUser.prioridades = await loadPrioridades();
    }

    if(storeUser.estados == null) {
        storeUser.estados = await loadEstados();
    }


    if(storeUser.clientes == null) {
        storeUser.clientes =  await loadClientes(storeUser.marcas);
    }

    
    if(storeUser.UserData == null) {
        storeUser.UserData =  await GetUserData();
    }


    const {Firstname, Lastname, Thumbnail}= storeUser.UserData.UserData



    const layoutPegable = () => {
        let classes = '';
        if( storeMenu.pegable == true) {
          
            classes += 'lg:pr-[5.9%] 2xl:pr-[5.9%] 3xl:pr-[5.9%] 4xl:pr-[5.9%] lg:pl-[100px] '
        }
        if(storeMenu.pegable == false) {
            
            classes += 'lg:pr-[5.9%] 2xl:pr-[5.9%] 3xl:pr-[5.9%] 4xl:pr-[5.9%] lg:pl-[149px] '
        }



       
        return classes.trim()
    }


    const layoutFather = () => {
        let classes = '';
        if( storeMenu.pegable == true) {

            classes += 'md:ml-[69px] '
        }
        if(storeMenu.pegable == false) {
            classes += 'md:ml-[240px] '
        }

        if(storeMenu.pegable == false && storeMenu.IsNotificacion == true) {
    
            classes= '';
            if(route.params.proyectos != undefined ) {
                classes += 'md:ml-[240px] w-[57%] '
            }
            switch (route.path) {
                case '/':
                    classes += 'md:ml-[240px] w-[57%] '
                    
                    break;
                case '/equipo':
                    classes += 'md:ml-[240px] w-[57%] '
                
                case '/reportes':
                    classes += 'md:ml-[240px] w-[57%] '
                case '/reportes':
                    classes += 'md:ml-[240px] w-[57%] '
                
                break;
            
                default:
                    break;
            }

           
        }
        
        return classes.trim()
    }


 

</script>
