<template>

 
   
    <div v-show="storeMenu.isNumberMobile" id="drawer-example" class="fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform -translate-x-full bg-[#0A47ED] w-80 " tabindex="-1" aria-labelledby="drawer-label">
 
        <button type="button" data-drawer-hide="drawer-example" aria-controls="drawer-example" class="text-white bg-transparent  rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center" >
            <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                <path d="M9.39989 7.04983L16.4498 14.0997L23.4997 7.04982L25.8497 9.39979L18.7998 16.4497L25.8497 23.4996L23.4997 25.8496L16.4498 18.7997L9.39989 25.8496L7.04992 23.4996L14.0998 16.4497L7.04992 9.3998L9.39989 7.04983Z" fill="white"/>
            </svg>
        </button>
        <div class="flex flex-col pl-[17px] pt-[33px]">
      
            <nuxt-link
                v-for="item in storeMenu.menu"
                :key="item.id"
                :to="item.to"
                @click="changeColor(item)"
                :class="`font-WT py-[25px] border-b-[1px] border-b-[#ffffff1a] flex justify-start  text-[#FFFFFF]  ${item.isActive ? 'font-[700]' : ''}`">
              
                
                <svg v-if="item.path" v-html="item.path" class="fill-white mr-[9px]" xmlns="http://www.w3.org/2000/svg" :viewBox="item.viewBox" :width="item.width" :height="item.height"></svg> 
                    {{ item.name }}  


            
            </nuxt-link>
            <p  class="font-WT text-white text-[14px] mt-[33.5px] mb-[25.5px]">Mis Clientes</p>
            <div v-for="(cliente, index) in storeUser.clientes" :key="index" :class="`border-b-[1px] border-solid last:border-b-[0] `">
                
            
                <div :class="`text-base pl-6 cursor-pointer flex items-center justify-between py-[25px] ${index == 0 ? 'pt-[0]':''} ` "   @click="OpenSubMenu(cliente)">
                    <div class="flex items-center" >
                        <p :class="`font-WT-Variable  rounded-[50px] font-[700] text-[10px] w-[22px] h-[22px] flex items-center justify-center mr-[6px] text-[#0A47ED] bg-white ${activeClientes(cliente.ClientId)  ? '' : ''}`" >{{formatted(storeUser.marcas,cliente.ClientId).charAt(0) }}</p>
                    
                        <p :class="`font-WT xs:text-[14px] lg:text-[16px] text-white ${activeClientes(cliente.ClientId) ? ' font-[700]' : ''}`" >{{ formatted(storeUser.marcas,cliente.ClientId)}}</p>
                    </div>


                    <svg :class="`cursor-pointer mr-[40px] stroke-[#ffffff]  ${cliente.isOpen ? 'rotate-180' : ''}` " 
                            xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
                        <path d="M9 1.5L5 5.5L1 1.5"  stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                    
                    

                </div>
                
                <div v-show=" cliente.isOpen" class="capitalize font-WT text-white  pl-[2rem] pr-[1rem] pb-[17px]" v-for="(proyecto, proyectoIndex) in cliente.proyectos" :key="proyectoIndex"  @click="activeClassMenu(cliente, proyecto)">
    
                
                    <nuxt-link active-class="text-white font-[700]" exact-active-class="text-white font-[700]"  :to="proyecto.link" class="text-[14px] " >{{ proyecto.name.toLowerCase() }}</nuxt-link>                    
                </div>

            </div>
        </div>
    </div>

   
  
    <div :class=" `${storeMenu.isNumberMobile ? 'hidden' : ''} h-min-custom h-full menu fixed z-[2] top-[0] ${storeMenu.pegable == true ? 'xs:w-[65px] lg:w-[65px]  bg-[#0A47ED] ' : ' bg-[#F8F8F8] xs:w-[200px] lg:w-[321px]'}  `"  v-show="!storeMenu.isNumberMobile">

        <div class="flex flex-col">
            <div :class="`flex border-solid border-l-[8px] border-solid ${storeMenu.pegable == true ? 'border-l-white h-[86px]' : 'justify-center lg:py-[20px] border-b-[1px] border-b-white border-l-[#0A47ED]'}`">
                
                <div class="flex justify-between items-center">
                    <nuxt-link to="/" v-if="storeMenu.pegable !=true">
                        <img src="~/assets/images/logo.svg" class="cursor-pointer" alt="WundermanThompson" width="133" height="46"/>
                    </nuxt-link>

                    <svg :class="`cursor-pointer ${storeMenu.pegable == true ? 'fill-white ml-[16px]' : 'fill-[#0A47ED] ml-[62px]'}`" width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg" @click="menuPlegable ">
                                <g id="Group 934">
                                    <rect id="Rectangle 827" width="22" height="3.25" rx="1.625"/>
                                    <rect id="Rectangle 828" y="6" width="22" height="3.25" rx="1.625" />
                                    <rect id="Rectangle 829" y="12" width="22" height="3.25" rx="1.625"/>
                                </g>
                            </svg>
                </div>
            
            </div>
        
            <div id="menu" :class="`flex flex-col  ${storeMenu.pegable == true ? 'lg:pt-[61px] px-[10px] justify-center' : 'xs:pl-[40px] xs:pr-[12.5px] xs:pt-[33px] lg:pl-[80px] lg:pr-[25px] lg:pt-[61px]'}`">
            
                <nuxt-link
                    v-for="item in storeMenu.menu"
                    :key="item.id"
                    :to="item.to"
                    @click="changeColor(item)"
                    :class="`menu-d py-[25px]  ${storeMenu.pegable == true ? 'border-b-[1px] border-b-[#ffffff1a] flex justify-center' : ''} ${item.class} ${item.isActive ? 'active-class' : 'text-[#212322]'}`">
                
                    
                    <svg v-if="item.path" v-html="item.path" :class="`${storeMenu.pegable == true ? 'fill-white' : 'mr-[9px]'}`" xmlns="http://www.w3.org/2000/svg" :viewBox="item.viewBox" :width="item.width" :height="item.height"></svg> 
                        {{ storeMenu.pegable == true ? '' : item.name }} 


                
                </nuxt-link>
                <p  v-if="storeMenu.pegable !=true" class="font-WT text-[#212322] text-[14px] mt-[71px] mb-[25px]">Mis Clientes</p>
                <div v-for="(cliente, index) in storeUser.clientes" :key="index" :class="`border-b-[1px] border-solid last:border-b-[0] ${ storeMenu.pegable == true ? 'mt-[25px] border-b-[#ffffff1a]' :'  border-black/20 '}`">
                    
                
                    <div :class="`text-base pl-6 cursor-pointer flex items-center justify-between py-[25px] ${index == 0 ? 'pt-[0]':''} ` "  v-if="storeMenu.pegable == false" @click="OpenSubMenu(cliente)">
                        <div class="flex items-center" >
                            <p :class="`font-WT-Variable text-white rounded-[50px] font-[700] text-[10px] w-[22px] h-[22px] flex items-center justify-center mr-[6px] ${activeClientes(cliente.ClientId)  ? 'bg-[#0A47ED]' : 'bg-[#212322]'}`" >{{formatted(storeUser.marcas,cliente.ClientId).charAt(0) }}</p>
                        
                            <p :class="`font-WT xs:text-[14px] lg:text-[16px] ${activeClientes(cliente.ClientId) ? 'text-[#0A47ED] font-[700]' : 'text-[#212322]'}`" >{{ formatted(storeUser.marcas,cliente.ClientId)}}</p>
                        </div>


                        <svg :class="`cursor-pointer mr-[40px]  ${activeClientes(cliente.ClientId) ? 'icon-active' : 'icon-not-active'}` + ` ${cliente.isOpen ? 'rotate-180' : ''}` " 
                        xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
                            <path d="M9 1.5L5 5.5L1 1.5"  stroke-width="1.5" stroke-linecap="round"/>
                        </svg>
                        
                        

                    </div>
                
                    <div class="flex items-center justify-center pb-[25px] cursor-pointer " v-if="storeMenu.pegable == true"  @click="pegableProyects(cliente)">
                        <div :class="` rounded-[50px] w-[22px] h-[22px] flex items-center justify-center ${activeClientes(cliente.ClientId) ? 'bg-[#ffffffff]':'bg-[#ffffffb3]'}`">
                            <p class="font-WT-Variable text-[#0A47ED] text-[10px] font-[700] capitalice ">{{formatted(storeUser.marcas,cliente.ClientId).charAt(0) }}</p>
                        </div>
                    
                    </div>
                    
                    <div v-show="storeMenu.pegable == false && cliente.isOpen" :class="`capitalize font-WT ${ storeMenu.pegable == true ? 'text-white' :' pl-[2rem] pr-[1rem] pb-[17px] text-[#212322]'}`" v-for="(proyecto, proyectoIndex) in cliente.proyectos" :key="proyectoIndex"  @click="activeClassMenu(cliente, proyecto)">
        
                    
                        <nuxt-link active-class="text-[#0A47ED]" exact-active-class="text-[#0A47ED] font-[700]"  :to="proyecto.link" class="text-[14px] " >{{storeMenu.pegable == true ? proyecto.name.split(" ").map(word => word.charAt(0)).join("").replace('-', '').toUpperCase() :  proyecto.name.toLowerCase() }}</nuxt-link>                    
                    </div>

                </div>
            </div>

            
        </div>

    </div>
   
</template>

<script setup>

    import debounce from 'lodash/debounce';

    import { onMounted, onBeforeUnmount } from "vue";

    import { useMenu } from '~/store/menu/index';

    import { useUser } from '~/store/user/index';

    import useLoadApi from '~/composables/useLoadApi';

    const storeMenu = useMenu();

    const storeUser = useUser();

    const {        
        formattedId,
        formatted
    } = useLoadApi()


    const route = useRoute();

        
    const activeClientes = (Id) => {
        const clienteMounted = storeUser.clientes.find(cliente => cliente.ClientId == Id);
    
        return window.proyectIsActive == clienteMounted.ClientId
      
    }


    const pegableProyects = (item) => {
        
        const clienteArray = Object.values(storeUser.clientes);
        const cliente  = clienteArray.find(c => c.ClientId ==  item.ClientId)
        cliente.isOpen = true
        storeMenu.pegable = !storeMenu.pegable;
        if (process.client) {
            localStorage.setItem('pegable', JSON.stringify(storeMenu.pegable));
        }
    }

    const menuPlegable = () => {
        storeMenu.pegable = !storeMenu.pegable;

        if (process.client) {
            localStorage.setItem('pegable', JSON.stringify(storeMenu.pegable));
        }
    };

    // Restaurar el valor al cargar la página
    if (process.client) {
        const storedValue = localStorage.getItem('pegable');
        if (storedValue !== null) {
            storeMenu.pegable = JSON.parse(storedValue);
        }
    }

    

    const changeColor = (item) => {
        const menuArray = Object.values(storeMenu.menu);
        const clienteArray = Object.values(storeUser.clientes);
        
        menuArray.forEach((item) => {
            if(item.isActive == true) {
                item.isActive = false,
                item.isOpen = false
            }
            
        })

        clienteArray.forEach((item) => {
            item.isOpen = false
            
        })

        window.proyectIsActive = null
        item.isActive = true
  
   
    }

    const activeClassMenu = (item, item2) => {

        const menuArray = Object.values(storeMenu.menu);
        const clienteArray = Object.values(storeUser.clientes);
        menuArray.forEach((item) => {
            if(item.isActive == true) {
                item.isActive = false
            }
            
        })
        clienteArray.forEach((item) => {
            item.isOpen = false
            
        })

        const cliente  = clienteArray.find(c => c.ClientId ==  item.ClientId)

        cliente.isOpen = true
  
        window.proyectIsActive = item.ClientId

        const elements = document.querySelectorAll(`.cliente${cliente.ClientId} p`);
            elements.forEach((element, index) => {
            
                if(index == 0) {
                    element.classList.add('bg-[#212322]');
                    element.classList.remove('bg-[#0A47ED]');
                }

                else {
                    element.classList.add('text-[#212322]');
                    element.classList.remove('text-[#0A47ED]');
                    element.classList.remove('font-[700]');
                }
                
            });
  
        item2.isActive = true

       


    } 


    const OpenSubMenu = (cliente) => {
        cliente.isActive = !cliente.isActive;
        cliente.isOpen = !cliente.isOpen;
        
    }


    const checkScreenWidth = () => {
        storeMenu.isNumberMobile = window.innerWidth <= 767; // Cambiar el valor según el ancho deseado para mobile
    };

    const debouncedCheckScreenWidth = debounce(checkScreenWidth, 1); // Ajusta el tiempo de debounce según sea necesario

    onMounted(() => {
    
        const currentItem = storeMenu.menu.find(item => item.to === route.path);

        if (Object.keys(route.params).length > 0 ) {
            const id = formattedId(storeUser.marcas, route.params.proyectos[0])
        
            const clienteMounted = storeUser.clientes.find(cliente => cliente.ClientId == id);
            clienteMounted.isOpen = true
            window.proyectIsActive = clienteMounted.ClientId
        }


        if (currentItem) {
            currentItem.isActive = true;
            
        }

        

        checkScreenWidth();

        window.addEventListener('resize', debouncedCheckScreenWidth);
    });

    

    onBeforeUnmount(() => {
    
        window.removeEventListener('resize', debouncedCheckScreenWidth);
   


    });
</script>

<style lang="scss" scoped>
    .h-min-custom {
        min-height: 100vh;
    }

    .active-class {
        @apply text-[#0A47ED] fill-[#0A47ED];
    }

    .icon-active {
        @apply stroke-[#0A47ED] ;
    }

    .icon-not-active {
        @apply stroke-[#212322];
    }

 



    .menu {
        @media only screen and (min-width: 768px) {
            display: block;
        }
    }
    .menu {
        @media only screen and (max-height: 900px) {
            @apply h-[600px] overflow-y-scroll pb-[50px]
        }
    }
</style>